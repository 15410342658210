<template>
  <v-container>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title> Alta de Persona </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit" autocomplete="off">
          <v-card-text>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo de Documento"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-select
                    ref="tipodocumento"
                    :items="TiposDocumento"
                    label="Tipo de Documento"
                    :error-messages="errors"
                    v-model="Persona.idTipoDocumento"
                    clearable
                    hide-selected
                    required
                    item-text="txtDesc"
                    item-value="id"
                    autocomplete="off"
                    tabindex="1"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Documento"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    @focus="CheckTipoDoc"
                    @blur="CheckDocumento"
                    @input="checkPersona = false"
                    ref="nrodocumento"
                    v-model="Persona.nroDocumento"
                    :error-messages="errors"
                    label="Documento"
                    type="number"
                    tabindex="2"
                    :append-outer-icon="checkPersona ? 'mdi-checkbox-marked-circle' : ''"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Apellidos"
                  :rules="{
                    min_value: 1,
                  }"
                >
                  <v-text-field
                    v-model="CantApe"
                    v-show="false"
                    :error-messages="errors"
                  >
                  </v-text-field>
                  <v-text-field
                    ref="apellido"
                    label="Apellido"
                    v-model="Ape"
                    @blur="addApellido"
                    tabindex="3"
                    v-on:keydown.space.prevent="addApellido"
                    :error-messages="
                      Persona.apellidos.length == 0
                        ? 'Debe ingresar al menos un apellido'
                        : ''
                    "
                  >
                    <template v-slot:append>
                      <v-btn icon @click="addApellido"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <template v-slot:prepend-inner>
                      <div
                        v-for="(Apellido, index) in Persona.apellidos"
                        :key="index"
                      >
                        <v-chip
                          class="ma-1"
                          label
                          outlined
                          close
                          @click:close="deleteApellido(index)"
                        >
                          {{ Apellido }}
                        </v-chip>
                      </div>
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombres"
                  :rules="{
                    min_value: 1,
                  }"
                >
                  <v-text-field
                    v-model="CantNom"
                    v-show="false"
                    :error-messages="errors"
                  >
                  </v-text-field>
                  <v-text-field
                    ref="nombre"
                    label="Nombre"
                    v-model="Nom"
                    @blur="addNombre"
                    tabindex="4"
                    v-on:keydown.space.prevent="addNombre"
                    :error-messages="
                      Persona.nombres.length == 0
                        ? 'Debe ingresar al menos un nombre'
                        : ''
                    "
                  >
                    <template v-slot:append>
                      <v-btn icon @click="addNombre"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <template v-slot:prepend-inner>
                      <div
                        v-for="(Nombre, index) in Persona.nombres"
                        :key="index"
                      >
                        <v-chip
                          class="ma-1"
                          label
                          outlined
                          close
                          @click:close="deleteNombre(index)"
                        >
                          {{ Nombre }}
                        </v-chip>
                      </div>
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Apellido materno"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    v-model="Persona.apellidoMaterno"
                    label="Apellido Materno"
                    type="text"
                    tabindex="5"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Fecha de nacimiento"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-text-field
                        v-model="Persona.fechaNacimiento"
                        label="Fecha de Nacimiento"
                        prepend-icon="mdi-calendar"
                        v-mask="'##-##-####'"
                        @input="SetBirthDate()"
                        v-bind="attrs"
                        v-on="on"
                        tabindex="6"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="BirthDate"
                    :max="new Date().toISOString().substr(0, 10)"
                    :min="BirthDateMin()"
                    @change="SetFechaNacimiento"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card outlined tile>
                  <v-card-text class="d-flex justify-center">
                    <v-row>
                      <v-col>
                        <div class="text-subtitle-1">Sexo</div>
                      </v-col>
                      <v-col>
                        <v-chip-group
                          v-model="Persona.sexo"
                          mandatory
                          active-class="primary--text"
                          @change="SexoGenero"
                        >
                          <v-chip> Otro </v-chip>
                          <v-chip> Femenino </v-chip>
                          <v-chip> Masculino </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card outlined tile>
                  <v-card-text class="d-flex justify-center">
                    <v-row>
                      <v-col>
                        <div class="text-subtitle-1">Género</div>
                      </v-col>
                      <v-col>
                        <v-chip-group
                          v-model="Persona.genero"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip> Otro </v-chip>
                          <v-chip> Femenino </v-chip>
                          <v-chip> Masculino </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  class="mr-4"
                  type="submit"
                  block
                  plain
                  :disabled="invalid"
                >
                  Guardar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block plain @click="clear"> Limpiar </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <v-dialog
      v-model="existePersona"
      max-width="80%"
    >
      <v-container>
        <v-card>
          <v-card-title class="h6 text-center"
            >Datos de persona existente con documento {{ PersonaExistente.txtDesc + ": " + PersonaExistente.NroDocumento }}</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="PersonaExistente.Apellidos" label="Apellidos" readonly outlined shaped>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="PersonaExistente.Nombres" label="Nombres" readonly outlined shaped>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="PersonaExistente.ApellidoMaterno" label="Apellido Materno" readonly outlined shaped>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="PersonaExistente.FechaNacimiento" label="Fecha de Nacimiento" readonly outlined shaped>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn block plain > No </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mr-4"
                  block
                  plain
                >
                  Si
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, max, min, oneOf, min_value } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import axios from "axios";
import { mapState, mapActions } from "vuex";

setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: "{_field_} no puede quedar vacio",
});

extend("max", {
  ...max,
  message: "{_field_} no puede tener mas de {length} caractéres",
});

extend("min", {
  ...min,
  message: "{_field_} no puede tener menos de {length} caractéres",
});

extend("min_value", {
  ...min_value,
  message: "{_field_} no puede quedar vacio",
});

extend("oneOf", {
  ...oneOf,
  message: "Debe seleccionar un item del combo.",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "Persona",
  data() {
    return {
      Ape: "",
      CantApe: 0,
      Nom: "",
      CantNom: 0,
      BirthDate: null,
      menu: false,
      TiposDocumento: [],
      Persona: {
        idTipoDocumento: null,
        nroDocumento: "",
        apellidos: [],
        nombres: [],
        apellidoMaterno: "",
        fechaNacimiento: null,
        sexo: null,
        genero: null,
      },
      PersonaExistente: {},
      checkPersona: false,
      existePersona: false,
    };
  },
  computed: {
    ...mapState(["usuario"]),
  },

  created() {
    this.cargaTiposDocumento();
  },
  methods: {
    ...mapActions(["setAlerta"]),

    CheckTipoDoc() {
      if(!this.Persona.idTipoDocumento){
        this.$refs.tipodocumento.focus()
        this.setAlerta({
          snackbar_color: "info",
          text_snackbar: 'Seleccione un tipo de documento',
          snackbar: true,
        });
      }
    },
    async CheckDocumento(){
      if(this.Persona.idTipoDocumento && this.Persona.nroDocumento && this.checkPersona === false){
        var response = await axios({
          url: process.env.VUE_APP_API + "Personas/persona/" + this.Persona.idTipoDocumento + "/" + this.Persona.nroDocumento,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
        });
        if(response.data.estado != 'OK'){
          this.checkPersona = true;
        }else{
          this.PersonaExistente = await response.data.data;
          this.PersonaExistente.Apellidos = JSON.parse(this.PersonaExistente.Apellidos).join(' ');
          this.PersonaExistente.Nombres = JSON.parse(this.PersonaExistente.Nombres).join(' ');
          this.PersonaExistente.FechaNacimiento = this.PersonaExistente.FechaNacimiento.substr(0,10).split('-')[2] + '-' + this.PersonaExistente.FechaNacimiento.substring(0,10).split('-')[1] + '-' + this.PersonaExistente.FechaNacimiento.substring(0,10).split('-')[0];
          this.checkPersona = false;
          this.existePersona = true;
          this.Persona.nroDocumento = null;
          this.$refs.nrodocumento.focus();
        }
      }
    },

    async cargaTiposDocumento() {
      var response = await axios({
        url: process.env.VUE_APP_API + "parametrizacion/TiposDocumento",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + this.usuario.token,
        },
      });
      this.TiposDocumento = response.data.data;
    },
    addApellido() {
      if(this.Ape.length){
        this.Persona.apellidos.push(this.Ape);
        this.Ape = "";
        this.CantApe = this.Persona.apellidos.length;
        setTimeout(() => {
          this.$refs.apellido.focus();
        }, 16);
      }
    },
    addNombre() {
      if(this.Nom.length){
        this.Persona.nombres.push(this.Nom);
        this.Nom = "";
        this.CantNom = this.Persona.nombres.length;
        setTimeout(() => {
          this.$refs.nombre.focus();
        }, 16);
      }
    },
    deleteApellido(index) {
      this.Persona.apellidos.splice(index, 1);
      this.CantApe = this.Persona.apellidos.length;
    },
    deleteNombre(index) {
      this.Persona.nombres.splice(index, 1);
      this.CantNom = this.Persona.nombres.length;
    },
    SetFechaNacimiento(date) {
      this.$refs.menu.save(date);
      this.Persona.fechaNacimiento =
        this.BirthDate.split("-")[2] +
        "-" +
        this.BirthDate.split("-")[1] +
        "-" +
        this.BirthDate.split("-")[0];
    },
    BirthDateMin() {
      var fecha = new Date();
      fecha.setFullYear(fecha.getFullYear() - 120);
      fecha = fecha.toISOString().substr(0, 10);
      return fecha;
    },
    SetBirthDate() {
      if (this.Persona.fechaNacimiento.length == 10) {
        this.BirthDate =
          this.Persona.fechaNacimiento.split("-")[2] +
          "-" +
          this.Persona.fechaNacimiento.split("-")[1] +
          "-" +
          this.Persona.fechaNacimiento.split("-")[0];
      }
    },
    SexoGenero() {
      this.Persona.genero = this.Persona.sexo;
    },
    async submit() {
      try {
        const personaNueva = this.Persona;
        switch(personaNueva.sexo){
          case 0:
            personaNueva.sexo = 'O';
          break;
          case 1:
            personaNueva.sexo = 'F';
          break;
          case 2:
            personaNueva.sexo = 'M';
          break;
        }
        switch(personaNueva.genero){
          case 0:
            personaNueva.genero = 'O';
          break;
          case 1:
            personaNueva.genero = 'F';
          break;
          case 2:
            personaNueva.genero = 'M';
          break;
        }
        personaNueva.fechaNacimiento = this.BirthDate;
        this.$refs.observer.validate();
        var response = await axios({
          url: process.env.VUE_APP_API + "Personas/Persona",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
          data: personaNueva,
        });
        if (response.data.estado == "OK") {
          this.setAlerta({
            snackbar_color: "success",
            text_snackbar:
              "Se agrego la persona",
            snackbar: true,
          });
          this.clear();
        } else {
          this.setAlerta({
            snackbar_color: "warning",
            text_snackbar: response.data.data.error,
            snackbar: true,
          });
        }
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },
    clear() {
      (this.Persona = {
        idTipoDocumento: null,
        nroDocumento: "",
        apellidos: [],
        nombres: [],
        apellidoMaterno: "",
        fechaNacimiento: null,
        sexo: null,
        genero: null,
      }),
        setTimeout(() => {
          this.$refs.observer.reset();
        }, 16);
    },
  },
};
</script>