<template>
    <v-container>
        <v-card elevation="0" class="ma-0 pa-0">
            <v-card-title>Persona</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
                <v-row>
                    <v-col
                    md="6"
                    >
                        <v-autocomplete
                            v-model="model"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            hide-no-data
                            item-text="Description"
                            item-value="API"
                            placeholder="Buscar..."
                            prepend-icon="mdi-account-search"
                            @change="ver"
                            return-object
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";

export default {
  name: "BuscarPersona",
  data() {
    return {
        descriptionLimit: 40,
        lenMin: 3,
        personas: [],
        isLoading: false,
        model: null,
        search: null,
    };
  },
  computed: {
    ...mapState(["usuario"]),
    fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },

      items () {
        return this.personas.map(persona => {
          const armaDescripcione = persona.TipoDocumento + ': ' + persona.NroDocumento + ' | ' + JSON.parse(persona.Apellidos).toString().replace(',', ' ') + ', ' + JSON.parse(persona.Nombres).toString().replace(',', ' ');
          const Description = armaDescripcione.length > this.descriptionLimit
            ? armaDescripcione.slice(0, this.descriptionLimit) + '...'
            : armaDescripcione

          return Object.assign({}, persona, { Description })
        })
      },

  },
  watch: {
      search (val) {
        if (val.length >= this.lenMin){
            // Items have already been loaded
            if (this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            fetch(process.env.VUE_APP_API + "Personas/buscar/" + val, 
            {
                headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + this.usuario.token,
                },
            })
            .then(res => res.json())
            .then(res => {
                this.personas = res.data
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => (this.isLoading = false))
        }else{
            this.personas = [];
        }
      },
    },

  methods: {
    ...mapActions(["setAlerta"]),
    async buscar() {
      try {
        var response = await axios({
          url: process.env.VUE_APP_API + "Personas/buscar/" + this.buscarPersona,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
          data: this.buscarPersona,
        });
        if (response.data.estado == "OK") {
          console.log(response.data);
          this.clear();
        } else {
          this.setAlerta({
            snackbar_color: "warning",
            text_snackbar: response.data.data.error,
            snackbar: true,
          });
        }
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },
    clear() {
        this.buscarPersona,
        setTimeout(() => {
          this.$refs.observer.reset();
        }, 16);
    },
    ver() {
        console.log(this.model)
    },
  },
};
</script>